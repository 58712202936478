import React from 'react'

import { Pagination } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { PaginationExample1 } from '../../examples/react/PaginationExample1'
import { PaginationExample2 } from '../../examples/react/PaginationExample2'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Pagination"
    components={[{ component: Pagination }]}
  >
    <Section>
      <SectionHeading>Linkeillä</SectionHeading>
      <Playground example={PaginationExample1} />
      <SectionHeading>Painikkeilla</SectionHeading>
      <Playground example={PaginationExample2} />
      <SectionHeading>Verkkopalvelu</SectionHeading>
      <Playground format="html">
        <nav
          aria-label="Sivutus"
          className="pagination"
        >
          <ol className="pagination-list">
            <li className="pagination-item pagination-item--hidden">
              <ButtonHTMLExample
                as="div"
                className="pagination-previous"
                iconLeft={<IconHTMLExample name="ChevronLeftIcon" />}
                variant="plain"
              >
                Edellinen
              </ButtonHTMLExample>
            </li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                aria-current="page"
                className="pagination-button"
              >
                1
              </ButtonHTMLExample>
            </li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                className="pagination-button"
                variant="outline"
              >
                2
              </ButtonHTMLExample>
            </li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                className="pagination-button"
                variant="outline"
              >
                3
              </ButtonHTMLExample>
            </li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                className="pagination-button"
                variant="outline"
              >
                4
              </ButtonHTMLExample>
            </li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                className="pagination-button"
                variant="outline"
              >
                5
              </ButtonHTMLExample>
            </li>
            <li aria-hidden="true">⋯</li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                className="pagination-button"
                variant="outline"
              >
                20
              </ButtonHTMLExample>
            </li>
            <li className="pagination-item">
              <ButtonHTMLExample
                as={LinkMock}
                className="pagination-next"
                iconRight={<IconHTMLExample name="ChevronRightIcon" />}
                variant="plain"
              >
                Seuraava
              </ButtonHTMLExample>
            </li>
          </ol>
        </nav>
      </Playground>
    </Section>
  </Content>
)

export default Page
