import React from 'react'
import { Link } from 'gatsby'

import { Pagination } from '@te-digi/styleguide'

const PaginationExample1 = () => (
  <Pagination
    ariaLabel="Sivutus"
    as={Link}
    currentPage={
      typeof window !== 'undefined'
        ? parseInt(window.location.hash.replace('#', '')) || 1
        : 1
    }
    linkProps={page => ({ to: `#${page}` })}
    pageCount={5}
  />
)

export { PaginationExample1 }
