import React, { useState } from 'react'

import { Pagination } from '@te-digi/styleguide'

const PaginationExample2 = () => {
  const [page, setPage] = useState(1)

  return (
    <Pagination
      ariaLabel="Sivutus"
      currentPage={page}
      onChange={e => setPage(e)}
      pageCount={20}
    />
  )
}

export { PaginationExample2 }
